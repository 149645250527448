/* begin general import */
import { DownOutlined } from "@ant-design/icons";
import { Button, Card, Col, Dropdown, Menu, Row, Tooltip } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import classNames from "classnames";
import InputSearch from "components/Utility/InputSearch/InputSearch";
import Pagination from "components/Utility/Pagination/Pagination";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import masterService from "services/pages/master-service";
import nameof from "ts-nameof.macro";
/* end general import */

/* begin filter import */
/* end filter import */

/* begin individual import */
import { StringFilter } from "@react3l/advanced-filters";
import AdvanceStringFilter from "components/Utility/AdvanceFilter/AdvanceStringFilter/AdvanceStringFilter";
import { deviceTypeMap } from "data/seeeds";
import { formatDate } from "helpers/date-time";
import { Device } from "models/Device";
import { DeviceFilter } from "models/Device/DeviceFilter";
import { Moment } from "moment";
import { deviceRepository } from "repositories/device-repository";
/* end individual import */

function DeviceMaster() {
  const [translate] = useTranslation();

  const {
    list,
    total,
    loadingList,
    filter,
    toggle,
    handleChangeFilter,
    handleUpdateNewFilter,
    handleToggleSearch,
    handleImportList,
    handleTableChange,
    handlePagination,
    rowSelection,
    importButtonRef
  } = masterService.useMaster<Device, DeviceFilter>(
    DeviceFilter,
    "",
    deviceRepository.list,
    deviceRepository.count,
  );

  const listMapping = list.reduce((acc:any, d) => {
    if (!acc.some(item => item.id === d.id)) {
      const joinType = deviceTypeMap.get(d.deviceTypeId);
      acc.push({ ...d, deviceTypeName: joinType?.typeName });
    }
    return acc;
  }, []);

	

  const _handleChangeFilter = React.useCallback(
    (value) => {
      const newFilter = { ...filter };
      newFilter["search"] = value;
      handleUpdateNewFilter(newFilter);
    },
    [filter, handleUpdateNewFilter]
  );

  const columns: ColumnProps<Device>[] = useMemo(
    () => [
      {
        title: (
          <div className="text-center gradient-text">
            {translate("Tên thiết bị")}
          </div>
        ),
        key: '1',
        dataIndex: 'name',
        sorter: true,
        width: 150,
        render(name: string) {
          return (
            <div className="ant-cell-master__container">
              <div
                className={classNames(
                  "cell-master__first-row",
                  "first-row--link"
                )}
              >
                {name}
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <div className="gradient-text">
            {translate("Loại thiết bị")}
          </div>
        ),
        key: '2',
        dataIndex: 'deviceTypeName',
        sorter: false,
        align: "left",
        width: 150,
        render(deviceTypeName: string) {
          return (
            <div className="ant-cell-master__container">
              <div
                className={classNames(
                  "cell-master__first-row",
                  "first-row--link"
                )}
              >
                {deviceTypeName}
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <div className="text-center gradient-text">
            {translate("Ngày tạo")}
          </div>
        ),
        key: '3',
        dataIndex: 'createdAt',
        sorter: true,
        width: 150,
        render(createdAt: Moment) {
          return (
            <div className="ant-cell-master__container">
              <div
                className={classNames(
                  "cell-master__first-row",
                  "first-row--link"
                )}
              >
                {formatDate(createdAt)}
                </div>
            </div>
          );
        },
      },
    ],
    [translate]
  );

  const filterChildren = useMemo(
    () => (
      <Row className="mt-4">
        <Col lg={4} className="pr-4">
          <label className="label">{translate("Tên thiết bị")}</label>
          <AdvanceStringFilter
            isMaterial={true}
            value={filter[nameof(list[0].name)]["contain"]}
            onEnter={handleChangeFilter(
              nameof(list[0].name),
              "contain" as any,
              StringFilter
            )}
            placeHolder="Tìm kiếm..."
          />
        </Col>

      </Row>
    ),
    [filter, handleChangeFilter, list, translate]
  );

  const [dropdown, setDropdown] = React.useState<boolean>(false);

  const handleDropdown = React.useCallback(() => {
    setDropdown(!dropdown);
  }, [dropdown]);

  const menuFilter = React.useMemo(
    () => (
      <Menu>
        <Menu.Item key="2">
          <Tooltip title={translate("general.button.importExcel")}>
            <>
              <input
                ref={importButtonRef}
                type="file"
                style={{ display: "none" }}
                id="master-import"
                onChange={handleImportList(deviceRepository.import)}
              />
              <div
                className="ant-action-menu"
                onClick={() => {
                  importButtonRef.current.click();
                }}
              >
                <i className="tio-file_add_outlined" /> {translate("general.button.importExcel")}
              </div>
            </>
          </Tooltip>
        </Menu.Item>
      </Menu>
    ),
    [handleImportList, importButtonRef, translate]
  );

  return (
    <>
      <div className="page page__master">
        <div className="page__header d-flex align-items-center justify-content-between">
          <div className="page__title">
            {translate("deviceTypes.master.title")}
          </div>
        </div>
        <div className="page__search">
          <Card bordered={false}>
            <div className="d-flex align-items-center">
              <div className="d-flex flex-grow-1">
                <div className="pr-4 w70">
                  <InputSearch 
                    value={filter["search"]}
                    onChange={_handleChangeFilter}
                    placeHolder="Tìm kiếm"
                  />
                </div>

                <button
                  className={classNames(
                    "btn component__btn-toggle mr-3 grow-animate-1",
                    toggle === true ? "component__btn-toggle-active" : ""
                  )}
                  onClick={handleToggleSearch}
                >
                  <i className="tio-tune_horizontal"></i>
                  <span className="component_btn-text">
                    {translate("general.button.advance")}
                  </span>
                </button>
              </div>
              <div className="d-flex justify-content-around ml-4">
                <div className="table__action">
                  <Dropdown overlay={menuFilter} trigger={["click"]}>
                    <Button onClick={handleDropdown}>
                      <span className="component_btn-text">
                        {translate("general.actions.action")}
                      </span>
                      <DownOutlined className={dropdown ? "dropdown" : null} />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </div>
            <CSSTransition
              in={toggle}
              timeout={100}
              classNames={"show"}
              unmountOnExit
            >
              {filterChildren}
            </CSSTransition>
          </Card>
        </div>
        <div className="page__master-table custom-scrollbar pb-4">
          <Card bordered={false}>
            <Table
              rowKey={nameof(list[0].id)}
              columns={columns}
              pagination={false}
              dataSource={listMapping}
              loading={loadingList}
              onChange={handleTableChange}
              rowSelection={rowSelection}
              scroll={{ x: "max-content" }}
              title={() => (
                <>
                  <div className="d-flex justify-content-end">
                    <div className="flex-shrink-1 d-flex align-items-center">
                      <Pagination
                        skip={filter.skip}
                        take={filter.take}
                        total={total}
                        onChange={handlePagination}
                        style={{ margin: "10px" }}
                      />
                    </div>
                  </div>
                </>
              )}
            />
          </Card>
        </div>
      </div>
    </>
  );
}

export default DeviceMaster;
