import { AuthContext } from "app/app-context";
import { UserRoleEnum } from "config/enum";
import React from "react";

export const useRoleService = () =>{
    const [appUserInfo] = React.useContext(AuthContext);

    const userRole:number[] = React.useMemo(() => {
      return appUserInfo?.appUserRoleMappings.map(a=>a.roleId)??[];
    }, [appUserInfo]);

    const isRoleSupportFeature = React.useCallback((roles: UserRoleEnum[]) => {
      return roles.some(r => userRole.includes(r));
    }, [userRole]);

    return {
        userRole,
        isRoleSupportFeature
    };
};