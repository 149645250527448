import { NumberFilter } from '@react3l/advanced-filters';
import { Col, Row } from 'antd';
import { AxisResponse, Dashboard, DashboardFilter } from 'models/Dashboard';
import React, { PropsWithChildren } from 'react';
import Chart from 'react-apexcharts';
import { Observable } from 'rxjs';

export const DonutChart = (props: PropsWithChildren<Props>) => {
    const {loadData, filter, height} = props;

    const [data, setData] = React.useState<any>();

    const optionsDonut = {
        labels: data?.horizontalAxis??[],
				responsive: [
					{
						breakpoint: 1200,
						options: {
							chart: {
								width: 300,
							},
							legend: {
								position: 'right',
							},
						},
					},
					{
						breakpoint: 768,
						options: {
							chart: {
								width: 200,
							},
							legend: {
								position: 'bottom',
							},
						},
					},
					{
						breakpoint: 480,
						options: {
							chart: {
								width: 150,
							},
							legend: {
								position: 'bottom',
								fontSize: '10px',
							},
						},
					},
				],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Tổng',
                  fontSize: '14px',
                  color: '#000',
                  formatter: function (w) {
                    var total = w.globals.seriesTotals.reduce((acc, val) => acc + val, 0);
                    return total;
                  }
                }
              }
            }
          }
        }
      };
    
    const total = data?.series[0].data.reduce((sum, item) => sum + item , 0);
    const processed = data?.series[0].data[2];

    React.useEffect(() => {
        const subscription = loadData(
            {
                time: new NumberFilter({ equal: filter.time }), 
                type: new NumberFilter({ equal: filter.type }),
                skip: 0,
                take: 1,
            }
        ).subscribe({
            next: (response: AxisResponse) => {
                setData(response);
            },
            error:() => {}
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [filter.time, filter.type, loadData]); 

    return (
      <>
        <Chart series={data?.series[0]?.data?.length>0? data?.series[0]?.data:[0]} options={optionsDonut} type={'donut'} height={height}>
        </Chart>
        <hr/>
          <Row className="d-flex align-items-center" justify='space-between'>
            <Col span={10} className="d-flex row pl-3 align-items-center">
              <div style={{width:35, height:35, backgroundColor:'#6EA6FF', borderRadius:100, marginRight: 15}}></div>
              <div className="">Đã xử lý</div>
            </Col>
            <Col className="text-center" span={7}>{processed??0}</Col>
            <Col className="text-center" span={7}>{processed?Math.round((processed/total)*100):0}%</Col>
          </Row>
          <hr/>
          <Row className="d-flex align-items-center" justify='space-between'>
            <Col span={10} className="d-flex row pl-3 align-items-center">
              <div style={{width:35, height:35, backgroundColor:'#F5803E', borderRadius:100, marginRight: 15}}></div>
              <div>Chưa xử lý</div>  
            </Col>
            <Col className="text-center" span={7}>{processed?total-processed:0}</Col>
            <Col className="text-center" span={7}>{processed?Math.round(((total-processed)/total)*100):0}%</Col>
          </Row>
          <hr/>
      </>
    );
};


interface Props{
    loadData?: (filter: DashboardFilter) => Observable<AxisResponse>;
    filter?: Dashboard;
    height?: number;
}