import { Button, Card, Col, Dropdown, Menu, Row, Table } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { ColumnProps } from "antd/lib/table";
import classNames from "classnames";
import AppFooter from "components/AppFooter/AppFooter";
import { IntegrateStatusEnum, UserRoleEnum } from "config/enum";
import {
  DORMITORY_DETAIL_ROUTE,
  END_USER_DETAIL_ROUTE,
  HOME_CONTROLLER_ROUTE,
} from "config/route-consts";
import { Device } from "models/Device";
import { DeviceType } from "models/DeviceType";
import { Firmware } from "models/Firmware";
import { FirmwareIntegration } from "models/FirmwareIntegration/FirmwareIntegration";
import { HomeController } from "models/HomeController";
import moment, { Moment } from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { homeControllerRepository } from "repositories/home-controller-repository";
import { Observable, of } from "rxjs";
import { authService } from "services/auth-services";
import detailService from "services/pages/detail-service";
import masterService from "services/pages/master-service";
import logo from "./../../../assets/images/hc-icon.png";
import nullAvatar from "./../../../assets/images/user/nullavatar.jpg";
import { HomeControllerCommandModal, useHomeControllerCommandModal } from './HomeControllerCommandModal';
import "./HomeControllerDetail.scss";
import { HomeControllerLogModal, useHomeControllerLogModal } from './HomeControllerLogModal';
import HomeControllerPreview from "./HomeControllerPreview";

function HomeControllerDetail() {
  const [translate] = useTranslation();
  const history = useHistory();

  const {userRole} = authService.useRole();

  const { model, handleGoBase } = detailService.useDetail(
    HomeController,
    homeControllerRepository.get,
    undefined,
    HOME_CONTROLLER_ROUTE
  );

  const handleGoDormitory = React.useCallback(
    (id: string | number) => (_event: any) => {
      if (id) {
        history.push(`${DORMITORY_DETAIL_ROUTE}?id=${id}`);
      }
    },
    [history]
  );

  const handleGoEndUser = React.useCallback(
    (id: string | number) => (_event: any) => {
      if (id) {
        history.push(`${END_USER_DETAIL_ROUTE}?id=${id}`);
      }
    },
    [history]
  );

  const {
    list: hcLogList,
    loading,
    visibleHomeControllerLogModal,
    openHomeControllerLogModal,
    cancelHomeControllerLogModal,
  } = useHomeControllerLogModal(model, homeControllerRepository.listLogs);

  const {
    visibleHomeControllerCommandModal,
    openHomeControllerCommandModal,
    cancelHomeControllerCommandModal,
  } = useHomeControllerCommandModal();



  const getChildDevices = (id: number | string): Observable<Device[]> => {
    if (!model || !model.devices || model.devices.length === 0) {
      return of([]);
    }
    return of(model?.devices?.filter(item => item.parentId === id));
  };
  const {
    isOpenPreview,
    isLoadingPreview,
    previewModel,
    handleOpenPreview,
    handleClosePreview
  } = masterService.usePreview<Device>(Device, getChildDevices);

  const menuAction = React.useCallback(
    (id: number, device: Device) => (
      <Menu>
        <Menu.Item key="1">
          <div className="ant-action-menu" onClick={handleOpenPreview(id)}>
            {translate("general.actions.view")}
          </div>
        </Menu.Item>
      </Menu>
    ),
    [handleOpenPreview, translate]
  );

  const deviceColumns: ColumnProps<Device>[] = [
    {
      title: <div className="gradient-text text-upper">Tên/MAC</div>,
      key: "0",
      dataIndex: "name",
      width: 200,
      render(name: string) {
        return (
          <div className="ant-cell-master__container">
            <div
              className={classNames("cell-master__first-row", {
                "first-row--ellipsis": true,
              })}
            >
              {name}
            </div>
          </div>
        );
      },
    },
    {
      title: <div className="gradient-text text-upper">Nhóm thiết bị</div>,
      key: "1",
      dataIndex: "deviceType",
      width: 200,
      render(deviceType: DeviceType) {
        return (
          <div className="ant-cell-master__container">
            <div
              className={classNames("cell-master__first-row", {
                "first-row--ellipsis": true,
              })}
            >
              {deviceType?.name}
            </div>
          </div>
        );
      },
    },
    {
      title: <div className="gradient-text text-upper">ID Thiết Bị</div>,
      key: "2",
      dataIndex: "id",
      width: 400,
      render(id: string) {
        return (
          <div className="ant-cell-master__container">
            <div className="cell-master__first-row">{id}</div>
          </div>
        );
      },
    },
    {
      title: <div className="gradient-text text-upper">FW Version</div>,
      key: "3",
      dataIndex: "firmwareVersion",
      width: 150,
      render(firmwareVersion: string) {
        return (
          <div className="ant-cell-master__container">
            <div className="cell-master__first-row">{firmwareVersion}</div>
          </div>
        );
      },
    },
    {
      title: (
        <div className="text-center gradient-text">
          {translate("general.actions.action")}
        </div>
      ),
      key: "action",
      dataIndex: "id",
      fixed: "right",
      width: 80,
      align: "center",
      render(id: number, device: Device) {
        return (
          <div className="d-flex justify-content-center button-action-table">
            <Dropdown
              overlay={menuAction(id, device)}
              trigger={["click"]}
              placement="bottomCenter"
              arrow
            >
              <span className="action__dots">...</span>
            </Dropdown>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <div className="page page__detail w-100 home-controller__container">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={6}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row user-info__wrapper" span={24}>
                <Card>
                  <div className="d-flex w-100 user-info__container flex-column">
                    <div className="user-info__header d-flex flex-column justify-content-center align-items-center">
                      <div className="user-info__image">
                        <img src={logo} alt={"IMG"} />
                      </div>
                      <div className="d-flex flex-column align-items-center mt-1">
                        <div className="user-info__label-field user-info__label-field--big">
                          {model.name}
                        </div>
                        <div className="user-info__label-content user-info__label-content--light mt-1">
                          Nhà của tôi
                        </div>
                      </div>
                    </div>
                    <div className="user-info__content d-flex flex-column flex-grow-1">
                      <div className="d-flex flex-column">
                        <div className="user-info__label-field user-info__label-field--light">
                          {translate("homeController.ipAddress")}
                        </div>
                        <div className="user-info__label-content user-info__label-content--bold mt-1">
                          {model.ipAddress}
                        </div>
                      </div>
                      <div className="d-flex flex-column mt-3">
                        <div className="user-info__label-field user-info__label-field--light">
                          {translate("homeController.version")}
                        </div>
                        <div className="user-info__label-content user-info__label-content--bold mt-1">
                          {model.version}
                        </div>
                      </div>
                      <div className="d-flex flex-column mt-3">
                        <div className="user-info__label-field user-info__label-field--light">
                          {translate("homeController.macAddress")}
                        </div>
                        <div className="user-info__label-content user-info__label-content--bold mt-1">
                          {model.macAddress}
                        </div>
                      </div>
                      <div className="d-flex flex-column mt-3">
                        <div className="user-info__label-field user-info__label-field--light">
                          {translate("homeController.timeZone")}
                        </div>
                        <div className="user-info__label-content user-info__label-content--bold mt-1">
                          {model.timeZone >= 0
                            ? `GMT +${model.timeZone}`
                            : `GMT ${model.timeZone}`}
                        </div>
                      </div>
                      {model.homeControllerActiveStatus && (
                        <div className="d-flex flex-column mt-3">
                          <div className="user-info__label-field user-info__label-field--light">
                            {translate("homeController.status")}
                          </div>
                          <div
                            className={`home-controller__status home-controller__status--${model.homeControllerActiveStatusId} mt-1`}
                          >
                            {model.homeControllerActiveStatus.name}
                          </div>
                        </div>
                      )}
                      {model.autoUpdate !== null && (
                        <div className="d-flex flex-column mt-3">
                          <div className="user-info__label-field user-info__label-field--light">
                            <span className="mr-2">{translate("homeController.autoUpdate")}</span>
                            <Checkbox checked={model.autoUpdate} disabled />
                          </div>
                        </div>
                      )}
                      <div className="d-flex flex-column control-center mt-2">
                        <Button onClick={openHomeControllerLogModal}>Check log</Button>
                        <Button onClick={openHomeControllerCommandModal}>Điều khiển từ xa</Button>
                      </div>
                    </div>
                    {userRole.includes(UserRoleEnum.Admin) && (
                      <div className="user-info__footer d-flex flex-column mt-3">
                        <div className="user-info__label-content user-info__label-content--bold">
                          <span>
                            <i className="tio-replay"></i> Reset HC
                          </span>
                        </div>
                        <div className="user-info__label-content user-info__label-content--bold mt-2">
                          <span>
                            <i className="tio-command_outlined"></i> VPN
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={18}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={24}>
                <div className="table__container w-100">
                  <div className="table__header w-100 d-flex justify-content-between">
                    <span className="card-title">Thiết bị</span>
                    <span className="card-title">
                      {model.deviceQuantity} Thiết bị
                    </span>
                  </div>
                  <div className="table__content">
                    <Table
                      rowKey={"id"}
                      columns={deviceColumns}
                      pagination={false}
                      dataSource={model?.devices?.filter(item => item.parentId === "00000000-0000-0000-0000-000000000000")}
                      rowSelection={null}
                      scroll={{ y: 400, x: "max-content" }}
                    ></Table>
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-4">
              <Col className="gutter-row" span={8}>
                <div className="card__container d-flex">
                  <div className="card__image">
                    <img
                      src={model ? model?.dormitory?.endUser?.avatar : nullAvatar}
                      alt={""}
                    />
                  </div>
                  <div
                    className="card__info d-flex flex-column justify-content-center"
                    onClick={handleGoEndUser(model.dormitory?.endUserId)}
                  >
                    <div className="info__title text-link">
                      {model.dormitory?.endUser?.displayName || "Không tên"}
                    </div>
                    <div className="info__description mt-1">
                      {model.dormitory?.endUser?.email || "(Không có Email)"}
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="gutter-row" span={8}>
                <div className="card__container d-flex">
                  <div className="card__image d-flex justify-content-center align-items-center">
                    <i className="tio-home_vs_1_outlined"></i>
                  </div>
                  <div
                    className="card__info d-flex flex-column w-100 justify-content-center "
                    onClick={handleGoDormitory(model.dormitoryId)}
                  >
                    <div className="info__title text-link">
                      {model.dormitory?.name || "(Không tên)"}
                    </div>
                    <div
                      className="info__description mt-1"
                      title={model.dormitory?.address}
                    >
                      {model.dormitory?.address || "(Không có địa chỉ)"}
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="gutter-row" span={8}>
                <div
                  className="card__container d-flex"
                  style={{ cursor: "default" }}
                >
                  <div className="card__image d-flex justify-content-center align-items-center">
                    <i className="tio-remaining_time"></i>
                  </div>
                  <div className="card__info d-flex flex-column justify-content-center">
                    <div className="info__title">
                      {model?.homeControllerActiveStatus?.name}
                    </div>
                    <div className="info__description mt-1">
                      {moment().from(model.createdAt as Moment, true)}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <AppFooter onCancel={handleGoBase}></AppFooter>
      </div>
      <HomeControllerLogModal
        list={hcLogList}
        loading={loading}
        visibleHomeControllerLogModal={visibleHomeControllerLogModal}
        cancelHomeControllerLogModal={cancelHomeControllerLogModal}
      />

      <HomeControllerPreview
        previewModel={previewModel[0]}
        isOpenPreview={isOpenPreview}
        isLoadingPreview={isLoadingPreview}
        handleClosePreview={handleClosePreview}
        translate={translate}
      />
      <HomeControllerCommandModal
        model={model}
        visibleHomeControllerCommandModal={visibleHomeControllerCommandModal}
        cancelHomeControllerCommandModal={cancelHomeControllerCommandModal}
      />
    </>
  );
}

export default HomeControllerDetail;
