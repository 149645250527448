/* begin general import */
import { StringFilter } from "@react3l/advanced-filters";
import { Card, Col, Dropdown, Menu, Row } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import classNames from "classnames";
/* end general import */
/* begin filter import */
import AdvanceStringFilter from "components/Utility/AdvanceFilter/AdvanceStringFilter/AdvanceStringFilter";
import InputSearch from "components/Utility/InputSearch/InputSearch";
import Pagination from "components/Utility/Pagination/Pagination";
import { formatDate } from "helpers/date-time";
import { Firmware, FirmwareFilter } from "models/Firmware";
import { Moment } from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
/* end filter import */
/* begin individual import */
import { UserRoleEnum } from "config/enum";
import { firmwareRepository } from "repositories/firmware-repository";
import { authService } from "services/auth-services";
import detailService from "services/pages/detail-service";
import masterService from "services/pages/master-service";
import { getAntOrderType } from "services/table-service";
import nameof from "ts-nameof.macro";
import FirmwareDetailModal from "../FirmwareDetail/FirmwareDetailModal";
import "./FirmwareMaster.scss";
import FirmwarePreview from "./FirmwarePreview";
import FirmwareUpdate, { useFirmwareUpdate } from "./FirmwareUpdate";
/* end individual import */

function FirmwareMaster() {
  const [translate] = useTranslation();

  const { isRoleSupportFeature } = authService.useRole();

  const {
    list,
    total,
    loadingList,
    filter,
    toggle,
    handleChangeFilter,
    handleToggleSearch,
    handleTableChange,
    handlePagination,
    handleServerDelete,
    handleSearch,
    handleUpdateNewFilter
  } = masterService.useMaster<Firmware, FirmwareFilter>(
    FirmwareFilter,
    "",
    firmwareRepository.list,
    firmwareRepository.count,
    firmwareRepository.delete,
    firmwareRepository.bulkDelete
  );

  const {
    isOpenPreview,
    isLoadingPreview,
    previewModel,
    handleOpenPreview,
    handleClosePreview
  } = masterService.usePreview<Firmware>(Firmware, firmwareRepository.get);

  const {
    model,
    isOpenDetailModal,
    isDetailModal,
    handleOpenDetailModal,
    handleCloseDetailModal,
    handleSaveModel,
    loadingModel,
    handleChangeSimpleField,
    handleChangeObjectField,
    dispatch,
    handleUpdateNewModel
  } = detailService.useDetailModal(
    Firmware,
    firmwareRepository.get,
    firmwareRepository.save,
    handleSearch
  );

  const {
    showUpdatePopup,
    handleOpenUpdatePopup,
    handleCloseUpdatePopup
  } = useFirmwareUpdate();

  const _handleChangeFilter = React.useCallback(
    value => {
      const newFilter = { ...filter };
      newFilter["search"] = value;
      handleUpdateNewFilter(newFilter);
    },
    [filter, handleUpdateNewFilter]
  );

  const handleGoCreate = React.useCallback(() => {
    handleClosePreview();
    handleOpenDetailModal(null);
  }, [handleClosePreview, handleOpenDetailModal]);

  const handleGoDetail = React.useCallback(
    (id: number) => () => {
      handleClosePreview();
      handleOpenDetailModal(id);
    },
    [handleClosePreview, handleOpenDetailModal]
  );

  const menuAction = React.useCallback(
    (id: number, firmware: Firmware) => (
      <Menu>
        {!loadingList && isRoleSupportFeature([UserRoleEnum.Admin, UserRoleEnum.Operate, UserRoleEnum.Supporter]) &&
          <Menu.Item key="1">
            <div className="ant-action-menu" onClick={handleOpenPreview(id)}>
              {translate("general.actions.view")}
            </div>
          </Menu.Item>
        }
        {
          isRoleSupportFeature([UserRoleEnum.Admin]) && (
            <>
              <Menu.Item key="2">
                <div className="ant-action-menu" onClick={handleGoDetail(id)}>
                  {translate("general.actions.edit")}
                </div>
              </Menu.Item>
              <Menu.Item key="3">
                <div
                  className="ant-action-menu"
                  onClick={() => handleServerDelete(firmware)}
                >
                  {translate("general.actions.delete")}
                </div>
              </Menu.Item>
            </>
          )
        }

      </Menu>
    ),
    [handleGoDetail, handleOpenPreview, handleServerDelete, isRoleSupportFeature, loadingList, translate]
  );

  const columns: ColumnProps<Firmware>[] = useMemo(
    () => [
      {
        title: (
          <div className="text-center gradient-text">
            {translate("firmwares.name")}
          </div>
        ),
        key: nameof(list[0].name),
        dataIndex: nameof(list[0].name),
        sorter: true,
        sortOrder: getAntOrderType<Firmware, FirmwareFilter>(
          filter,
          nameof(list[0].name)
        ),
        width: 100,
        render(name: string) {
          return (
            <div className="ant-cell-master__container">
              <div
                className={classNames("cell-master__first-row", {
                  "first-row--ellipsis": name && name.length >= 30
                })}
              >
                {name}
              </div>
            </div>
          );
        }
      },
      {
        title: (
          <div className="text-center gradient-text">
            {translate("firmwares.version")}
          </div>
        ),
        key: nameof(list[0].version),
        dataIndex: nameof(list[0].version),
        sorter: true,
        sortOrder: getAntOrderType<Firmware, FirmwareFilter>(
          filter,
          nameof(list[0].version)
        ),
        width: 40,
        render(version: string) {
          return (
            <div className="ant-cell-master__container">
              <div
                className={classNames("cell-master__first-row", {
                  "first-row--ellipsis": version && version.length >= 30
                })}
              >
                {version}
              </div>
            </div>
          );
        }
      },
      {
        title: (
          <div className="text-center gradient-text">
            {translate("firmwares.checksum")}
          </div>
        ),
        key: nameof(list[0].checksum),
        dataIndex: nameof(list[0].checksum),
        sorter: true,
        sortOrder: getAntOrderType<Firmware, FirmwareFilter>(
          filter,
          nameof(list[0].checksum)
        ),
        width: 200,
        render(checksum: string) {
          return (
            <div className="ant-cell-master__container">
              <div className={classNames("cell-master__first-row breakword")}>
                {checksum}
              </div>
            </div>
          );
        }
      },
      {
        title: (
          <div className="text-center gradient-text">
            {translate("firmwares.hcCount")}
          </div>
        ),
        key: nameof(list[0].hcCount),
        dataIndex: nameof(list[0].hcCount),
        sorter: true,
        sortOrder: getAntOrderType<Firmware, FirmwareFilter>(
          filter,
          nameof(list[0].hcCount)
        ),
        width: 100,
        render(hcCount: number) {
          return (
            <div className="ant-cell-master__container">
              <div className={classNames("cell-master__first-row breakword")}>
                {hcCount}
              </div>
            </div>
          );
        }
      },
      {
        title: (
          <div className="text-center gradient-text">
            {translate("firmwares.homeControllerTypeId")}
          </div>
        ),
        key: nameof(list[0].homeControllerTypeId),
        dataIndex: nameof(list[0].homeControllerTypeId),
        sorter: true,
        sortOrder: getAntOrderType<Firmware, FirmwareFilter>(
          filter,
          nameof(list[0].homeControllerTypeId)
        ),
        width: 100,
        render(homeControllerTypeId: number) {
          return (
            <div className="ant-cell-master__container">
              <div className={classNames("cell-master__first-row breakword")}>
                {homeControllerTypeId}
              </div>
            </div>
          );
        }
      },
      {
        title: (
          <div className="text-center gradient-text">
            {translate("firmwares.createdAt")}
          </div>
        ),
        key: nameof(list[0].createdAt),
        dataIndex: nameof(list[0].createdAt),
        sorter: true,
        sortOrder: getAntOrderType<Firmware, FirmwareFilter>(
          filter,
          nameof(list[0].createdAt)
        ),
        width: 110,
        align: "center",
        render(createdAt: Moment) {
          return (
            <div className="ant-cell-master__container">
              <div className={classNames("cell-master__first-row")}>
                {formatDate(createdAt)}
              </div>
            </div>
          );
        }
      },

      {
        title: (
          <div className="text-center gradient-text">
            {translate("general.actions.action")}
          </div>
        ),
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 80,
        align: "center",
        render(id: number, firmware: Firmware) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              <Dropdown
                overlay={menuAction(id, firmware)}
                trigger={["click"]}
                placement="bottomCenter"
                arrow
              >
                <span className="action__dots">...</span>
              </Dropdown>
            </div>
          );
        }
      }
    ],
    [translate, list, filter, menuAction]
  );

  const filterChildren = useMemo(
    () => (
      <>
        <Row className="mt-4">
          <Col lg={4} className="pr-4">
            <label className="label">{translate("firmwares.name")}</label>
            <AdvanceStringFilter
              isMaterial={true}
              value={filter[nameof(list[0].name)]["contain"]}
              onEnter={handleChangeFilter(
                nameof(list[0].name),
                "contain" as any,
                StringFilter
              )}
              placeHolder={translate("firmwares.placeholder.name")}
            />
          </Col>
          <Col lg={4} className="pr-4">
            <label className="label">{translate("firmwares.version")}</label>
            <AdvanceStringFilter
              isMaterial={true}
              value={filter[nameof(list[0].version)]["contain"]}
              onEnter={handleChangeFilter(
                nameof(list[0].version),
                "contain" as any,
                StringFilter
              )}
              placeHolder={translate("firmwares.placeholder.version")}
            />
          </Col>

          <Col lg={4} className="pr-4">
            <label className="label">{translate("firmwares.checksum")}</label>
            <AdvanceStringFilter
              isMaterial={true}
              value={filter[nameof(list[0].checksum)]["contain"]}
              onEnter={handleChangeFilter(
                nameof(list[0].checksum),
                "contain" as any,
                StringFilter
              )}
              placeHolder={translate("firmwares.placeholder.checksum")}
            />
          </Col>
        </Row>
      </>
    ),
    [filter, handleChangeFilter, list, translate]
  );

  return (
    <>
      <div className="page page__master firmware__container">
        <div className="page__header d-flex align-items-center justify-content-between">
          <div className="page__title">
            {translate("firmwares.master.title")}
          </div>
        </div>
        <div className="page__search">
          <Card bordered={false}>
            <div className="d-flex align-items-center">
              <div className="d-flex flex-grow-1">
                <div className="pr-4 w70">
                  <InputSearch
                    value={filter["search"]}
                    onChange={_handleChangeFilter}
                    placeHolder="Tìm kiếm"
                  />
                </div>

                <button
                  className={classNames(
                    "btn component__btn-toggle mr-3 grow-animate-1",
                    toggle === true ? "component__btn-toggle-active" : ""
                  )}
                  onClick={handleToggleSearch}
                >
                  <i className="tio-tune_horizontal"></i>
                  <span className="component_btn-text">
                    {translate("general.button.advance")}
                  </span>
                </button>
              </div>

              <div className="d-flex justify-content-around ml-4">
                {isRoleSupportFeature([UserRoleEnum.Admin]) &&
                  <button
                    className="btn component__btn-toggle grow-animate-1"
                    onClick={handleGoCreate}
                  >
                    <i className="tio-add"></i>
                    <span className="component_btn-text">
                      {translate("general.actions.create")}
                    </span>
                  </button>
                }
                {!loadingList && isRoleSupportFeature([UserRoleEnum.Admin, UserRoleEnum.Operate, UserRoleEnum.Supporter]) &&
                  <button
                    className="btn component__btn-primary grow-animate-1 ml-4"
                    onClick={handleOpenUpdatePopup}
                  >
                    <i className="tio-update"></i>
                    <span className="component_btn-text">
                      {translate("firmwares.actions.update")}
                    </span>
                  </button>
                }
              </div>
            </div>
            <CSSTransition
              in={toggle}
              timeout={100}
              classNames={"show"}
              unmountOnExit
            >
              {filterChildren}
            </CSSTransition>
          </Card>
        </div>
        <div className="page__master-table custom-scrollbar pb-4">
          <Card bordered={false}>
            <Table
              rowKey={nameof(list[0].id)}
              columns={columns}
              pagination={false}
              dataSource={list}
              loading={loadingList}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
              title={() => (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="action__result">{total} Kết quả</span>
                    <div className="flex-shrink-1 d-flex align-items-center">
                      <Pagination
                        skip={filter.skip}
                        take={filter.take}
                        total={total}
                        onChange={handlePagination}
                        style={{ margin: "10px" }}
                      />
                    </div>
                  </div>
                </>
              )}
            />
          </Card>
        </div>
      </div>
      <FirmwarePreview
        previewModel={previewModel}
        isOpenPreview={isOpenPreview}
        isLoadingPreview={isLoadingPreview}
        handleClosePreview={handleClosePreview}
        handleGoDetail={handleGoDetail}
        translate={translate}
      />
      <FirmwareDetailModal
        model={model}
        isDetail={isDetailModal}
        visible={isOpenDetailModal}
        handleSave={handleSaveModel}
        handleCancel={handleCloseDetailModal}
        onChangeSimpleField={handleChangeSimpleField}
        onChangeObjectField={handleChangeObjectField}
        dispatchModel={dispatch}
        loading={loadingModel}
        visibleFooter={true}
        handleUpdateNewModel={handleUpdateNewModel}
      />
      <FirmwareUpdate
        isOpenPopup={showUpdatePopup}
        handleClosePopup={handleCloseUpdatePopup}
        data={list}
      />
    </>
  );
}

export default FirmwareMaster;
